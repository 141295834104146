.App {
  text-align: center;
  background-color: #282c34;
  color: white;
}

.Search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 30vmin;
  max-height: 150px;
  pointer-events: none;
}

.App a {
  color: #8cc5fa;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.user-list {
  margin: auto !important;
  min-width: 50em;
  max-width: 80em;
  width: 60%;
  font-size: 1.2em !important;
}

.user-item-container
{
  display: grid;
  grid-template-columns: 60px 1fr 1fr 1fr 1fr;
  width: 100%;
  text-align: left;
}

.user-item-container .svg-inline--fa {
  padding-right: 5px;
  font-size: 1.2em;
  vertical-align: text-bottom;
}

.user-avatar
{
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: span 2;
  place-self: center;
}

.user-data-r1-c2
{
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
}

.user-data-r1-c3
{
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.user-data-r1-c4
{
  grid-column-start: 4;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
}

.user-data-r1-c5
{
  grid-column-start: 5;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 1;
}

.user-data-r2-c2
{
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
}

.user-data-r2-c3
{
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
}

.user-data-r2-c4
{
  grid-column-start: 4;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
}
